.logo-item {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 0 0 7px;
  height: 100px;
  width: 100px;
  box-sizing: border-box;
  border: 1px solid transparent;
  cursor: pointer;

  &:hover,
  &:focus {
    box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.12);
    background-color: #fff;
    border-color: rgba(204, 204, 204, 0.8);
  }

  .icon {
    margin: 0 !important;
    box-shadow: none;
    background: transparent;
    background-color: transparent !important;
    padding: 10px !important;

    svg,
    img {
      width: 50px;
      height: 50px;
    }
  }

  > span {
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 17px;
    max-height: 34px;
    font-size: 100%;
    color: #000;
    text-align: center;
  }
}

.status-board {
  font-size: 20px;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 4px;

  span {
    color: #fff;
    font-family: "proxima-nova", "Helvetica Neue", Helvetica, Arial, Sans-Serif;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.8rem;
  }

  + span {
    cursor: pointer;
  }
}

.home-page-card {
  margin-top: 0px !important;
}
