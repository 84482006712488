@import "src/App/Layout/_layout.scss";

.site-container {
  height: 100%;
  header {
    height: 70px;
    background-color: $color-black;
    border-radius: 0;
    svg {
      color: $color-white;
    }

    .dropdownItemWithIcon {
      display: flex;
      flex-direction: row;

      svg {
        margin-right: 0;
        margin-left: auto;
        color: $color-black;
      }

      &:hover {
        svg {
          color: $color-white;
        }
      }
    }
  }
  footer {
    a {
      &:hover,
      &:focus {
        color: lighten($primaryColor, 30%);
      }
    }
  }
}

.user-name-section {
  display: flex;
  align-items: center;
  cursor: pointer;
  .user-name-label {
    color: $color-white;
    text-align: right;

    .user-ItemSubText {
      font-size: 12px;
    }

    label {
      cursor: pointer;
      line-height: 18px !important;
    }
  }
}

.class-manager-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: 0;
}

.circular-progress-bar-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  svg {
    color: $primaryColor;
  }
}
