.roadmap {
    &-progress-bar {
        margin-bottom: -30px;
    }
    &-header-container {
        display: flex;
        flex-direction: row;
        @media screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
    &-percentages-container {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
    }
    &-percentage-container {
        display: flex;
        flex-direction: column;
        align-content: space-between;
    }
    &-card {
        height: 80%;
        min-width: 50px;
        &-difot {
            width: 380px !important;
            @media screen and (max-width: 600px) {
                width: 100% !important;
            }
        }
    }
    &-table {
        .rt-td {
            > div {
                height: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center; /* horizontally align items in center */
                align-items: center; /* vertically align items in center */
            }
        }

        .rt-td:first-child {
            > div {
                justify-content: flex-start;
            }
        }

        .rt-thead {
            .rt-th {
                font-weight: 500 !important;
                text-align: left !important;
            }

            .rt-th.-cursor-pointer {
                > div:first-of-type:after {
                    top: 5px !important;
                    right: 0 !important;
                    background-repeat: no-repeat;
                    content: url('../Shared/Assets/sort-up-arrow.svg') !important;
                }
            }

            .rt-th.-cursor-pointer.-sort-asc {
                > div:first-of-type:after {
                    top: 5px !important;
                    right: 0 !important;
                    content: url('../Shared/Assets/sort-up-arrow.svg') !important;
                }
            }

            .rt-th.-cursor-pointer.-sort-desc {
                > div:first-of-type:after {
                    top: 5px !important;
                    right: 0 !important;
                    content: url('../Shared/Assets/sort-down-arrow.svg') !important;
                }
            }
        }
    }
}
