@import "src/Shared/Styles/_variables.scss";
@import "src/Shared/Styles/_colors.scss";
@import "src/App/_styles.scss";
@import "src/HomePage/_homePage.scss";
@import "src/Roadmap/_roadmap.scss";
@import "src/ie11Styles.scss";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  overflow: hidden;
  background-color: #eeeeee;
  color: #3c4858;
  font-size: 14px;
}

html,
body {
  margin: 0;
  height: 100%;
}
body {
  height: 100%;
}

#root {
  height: 100%;
}

footer {
  padding: 0px !important;
}

.container {
  height: 100%;
  overflow-y: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
}

.card {
  .card-icon {
    svg {
      color: $color-black;
    }
  }

  .card-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    .card-header-section {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-top: 15px;
      flex-wrap: wrap;
      flex-grow: 1;
      min-height: 45px;

      > button {
        margin-left: auto !important;
      }

      .card-title {
        padding-left: 10px;
        padding-right: 10px;
        font-size: 25px;
        color: #3c4858;
        margin-top: 0px;
        margin-bottom: 0px;
        min-height: auto;
        font-weight: 300;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        text-decoration: none;
        flex-grow: 5;
      }
    }
  }
}
